// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-js": () => import("/builds/staltz/manyverse-site/src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-faq-js": () => import("/builds/staltz/manyverse-site/src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-pages-404-js": () => import("/builds/staltz/manyverse-site/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apk-js": () => import("/builds/staltz/manyverse-site/src/pages/apk.js" /* webpackChunkName: "component---src-pages-apk-js" */),
  "component---src-pages-blog-index-js": () => import("/builds/staltz/manyverse-site/src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contribute-js": () => import("/builds/staltz/manyverse-site/src/pages/contribute.js" /* webpackChunkName: "component---src-pages-contribute-js" */),
  "component---src-pages-design-js": () => import("/builds/staltz/manyverse-site/src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-develop-js": () => import("/builds/staltz/manyverse-site/src/pages/develop.js" /* webpackChunkName: "component---src-pages-develop-js" */),
  "component---src-pages-donate-js": () => import("/builds/staltz/manyverse-site/src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-download-js": () => import("/builds/staltz/manyverse-site/src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-eula-js": () => import("/builds/staltz/manyverse-site/src/pages/eula.js" /* webpackChunkName: "component---src-pages-eula-js" */),
  "component---src-pages-faq-index-js": () => import("/builds/staltz/manyverse-site/src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-features-js": () => import("/builds/staltz/manyverse-site/src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("/builds/staltz/manyverse-site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("/builds/staltz/manyverse-site/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-roadmap-js": () => import("/builds/staltz/manyverse-site/src/pages/roadmap.js" /* webpackChunkName: "component---src-pages-roadmap-js" */),
  "component---src-pages-team-js": () => import("/builds/staltz/manyverse-site/src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-translations-js": () => import("/builds/staltz/manyverse-site/src/pages/translations.js" /* webpackChunkName: "component---src-pages-translations-js" */),
  "component---src-pages-ux-research-js": () => import("/builds/staltz/manyverse-site/src/pages/ux-research.js" /* webpackChunkName: "component---src-pages-ux-research-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/builds/staltz/manyverse-site/.cache/data.json")

